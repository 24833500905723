.theme-chooser {
	position: fixed;

	bottom: 2rem;
	right: 2rem;

	padding: 1rem;
	border: calc(2 * var(--border-width)) solid var(--border-color);
	border-radius: var(--border-radius);
	background-color: white;
	min-width: 200px;
}
